import React, { useMemo, useState, useContext, useEffect, useRef } from 'react';
import { Divider, Drawer, IconButton, Typography } from '@material-ui/core';
import _ from 'lodash';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { bffGetUserProfileByIDs } from 'fitbud/api';
import ClientContent from './content';
import firebase from 'firebase';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { prepareClassData } from '../helper';

const ClientDrawer = (props) => {
  const { onClose, open, usersMemo, refreshClass, instanceId, forceDisabled, view_mode } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { cid, userProfile } = useContext(FirebaseAuthContext);
  // const [fetchedUsers, setFetchedUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const [filter, setFilter] = useState(undefined); //for chip selector
  const users = _.get(data, 'users');
  const firstLoad = useRef(true);
  const [userFetching, setUserFetching] = useState(false);
  const staffId = useMemo(() => {
    return _.get(userProfile, 'uid');
  }, [userProfile]);

  useEffect(() => {
    setLoading(true);
    showLoader();
    const unsubscribe = firebase
      .firestore()
      .doc(`/companies/${cid}/gcInstances/${instanceId}`)
      .onSnapshot(
        async (doc) => {
          const gcInstance = prepareClassData(doc.data())
          const users = _.get(gcInstance, 'users', {});
          const usersToFetch = Object.keys(users);
          setUserFetching(true);
          await fetchUser(usersToFetch);
          setUserFetching(false);
          setData(gcInstance);
          setLoading(false);
          if (!!firstLoad.current) hideLoader();
          firstLoad.current = false;
        },
        (error) => {
          setLoading(false);
          hideLoader();
        }
      );
    return () => {
      unsubscribe();
    };
  }, [instanceId]);

  const fetchUser = async (usersToFetch) => {
    const preFetchedUserIds = _.filter(usersToFetch, (uid) => !_.isEmpty(usersMemo.current[uid]));
    const notFetchedUsersIds = _.without(usersToFetch, ...(preFetchedUserIds || []));
    let usersMap = {};
    _.forEach(preFetchedUserIds, (uid) => {
      usersMap[uid] = _.get(usersMemo.current, uid);
    });

    try {
      if (!!notFetchedUsersIds.length) {
        const uids = usersToFetch.map((i) => i.split(':').pop());
        const response = await bffGetUserProfileByIDs({ cid, uids, fields: ['name', 'email', 'image', 'identifier', 'image_data'] });
        const data = _.get(response, 'data.data');
        const map = data.reduce((prev, current) => {
          const id = (current._id).split(':').pop();
          prev[id] = { ...current };
          return prev;
        }, {});
        //store at map as well, preparing object like : <uid> : {_id, name, email, image}
        usersMemo.current = { ...usersMemo.current, ...map };
        usersMap = { ...usersMap, ...map };
      }
    } catch (err) {
      //@TODO:: need to handle error stuff.
    }
  };

  //create array of users.
  const userArray = useMemo(() => {
    return _.chain(users)
      .keys()
      .map((user) =>{
        const user_id = (user || "").split(":").pop();
        return { _id: user_id, ...users[user], data: _.get(usersMemo.current, user_id, {}) }
      })
      .value();
  }, [users]);

  //waiting count.
  const waitingCount = useMemo(() => {
    return _.filter(userArray, (user) => user.status === 'waiting').length || 0;
  }, [userArray]);

  //filter effects
  const filteredUserArray = useMemo(() => {
    let allUsers = _.filter(userArray, (user) => user.status !== 'kicked'); //removed kicked users from array.
    if (!filter || !filter.length) return allUsers;
    let [mode, status] = filter;
    status = status == 'booked' ? 'yes' : status; // status key for booked is yes.
    const out = _.filter(allUsers, (user) => {
      //these are filters fors past.
      if(status === "joined") return !!user?.joined; //if joined filter applied then show all users which has joined classes no need to check mpde.
      if(status === "missed") return !user?.joined && user.status === "yes"; // users who not joined but status is yes ie booked classes.
      if(mode === "all" && status === "waiting"){
       return !user?.joined && user.status === "waiting";
      }
      //filters for current and upcoming..
      let isStatusMatch = _.get(user, 'status') === status;
      let isModeMatch = true; //if hybrid then show all users
      if (mode !== 'hybrid') isModeMatch = _.get(user, 'mode') === mode;
      return isStatusMatch &&  isModeMatch;
    });
    return out;
  }, [userArray, filter]);

  const isFilterApplied = useMemo(() => {
    if (!filter || !filter.length) return false;
    return true;
  }, [filter]);
  if (loading && firstLoad.current) return null; //if first time loading then return nothing
  return (
    <Drawer onClose={onClose} open={open} anchor="right">
      {!_.isEmpty(data) && (
        <ClientContent
          onClose={onClose}
          staffId={staffId}
          filter={filter}
          users={filteredUserArray}
          refreshClass={refreshClass}
          data={data}
          setFilter={setFilter}
          isFilter={isFilterApplied}
          waitingCount={waitingCount}
          usersMemo={usersMemo}
          userFetching={userFetching}
          instanceId={instanceId}
          forceDisabled={forceDisabled}
          view_mode={view_mode}
        />
      )}
      {!!_.isEmpty(data) && (
        <div className="d-flex flex-column h-100">
          <div style={{ height: 60 }} className="d-flex align-items-center px-20">
            <IconButton edge="start" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <div className=" flex-grow-1 align-items-center d-flex flex-grow-1 justify-content-center">
            <Typography className="font_16_500">Something went wrong, try again after some time.</Typography>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default ClientDrawer;
