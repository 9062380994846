import { IconButton, makeStyles, Tooltip, Typography, CircularProgress, Link } from '@material-ui/core';
import { Link as RLink } from "react-router-dom";
import { MailOutlined, PinDropOutlined, VisibilityOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';
import firebase from "fitbud/firebase";
import { AndroidIcon } from 'fitbud/icons/androidIcon';
import { AppleIcon } from 'fitbud/icons/appleIcon';
import { CopyIcon } from 'fitbud/icons/copy2';
import { ExternalLinkIcon } from 'fitbud/icons/externalLinkIcon';
import { MoneyIcon } from 'fitbud/icons/money';
import { CalendarIcon } from 'fitbud/icons/calendarIconAlt';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useLiveSessions } from 'fitbud/providers/liveSessionsProvider';
import appRdxFns from "fitbud/redux/app";
import { findBookingStatus, structureAppointment } from 'fitbud/utils/scheduling';
import linkifyHtml from 'linkifyjs/html';
import { isEmpty, truncate, get, capitalize } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { refreshConsultoria } from 'fitbud/api/consultoria';
import { useUserContext } from '../detail';
import { getUserLastSeen } from '../helpers';
import NoteDialog from '../userNotes/noteDialog';
import { ParentCard } from './subComponents';
import VideoCallsDrawer from './videoCallDrawer';
import { formatCurrency, formatNo } from 'fitbud/utils/helpers';
import { getStatusAndColor } from '../callHistory';

const styles= makeStyles((theme) => ({
  statusBadge: {
    padding:'3px 6px',
    color: ({bgColor}) => bgColor,
  },
  callNoteDiv: {
    '& *':{
      display:'inline',
      fontSize: '13px',
      whiteSpace: 'none',
      margin: "0px !important",
      padding: "0px !important",
    },
    '& br':{display: 'none'},
  },
  actionButton: { 
    whiteSpace: "nowrap", 
    backgroundColor: 'rgba(49, 127, 245, 0.1)',
    color: '#317FF5',
    boxShadow:'none !important',
    "&:hover":{
      backgroundColor: 'rgba(49, 127, 245, 0.2)',
    }
  },
  summaryIconMui: {
    color: '#65768C',
    width: '16px',
    height: '16px',
    opacity: '0.5'
  }
}));
const mostRecentSession = async(uid, cid) => {
  const docRef = firebase.firestore().collection(`companies/${cid}/bookings`).where(`uid`, "==", uid);
  let snapshot = await docRef.where('time', '>=', moment().toDate()).get();
  let out = [];
  if(!snapshot.empty){
    snapshot.forEach((doc) => doc.data().status === 'booked' && out.push(doc));
    if(!out.length) return { empty: true };
    return out.sort((doc1, doc2) => doc2.data().time.toDate() - doc1.data().time.toDate());
  };
  return snapshot;
};
const RightSection = props => {
  const { loadSessions, sessionDocs, loadNotes, noteDocs, openMessageDialog } = props;
  const { profile, userDoc, docId, toggleNotesDrawer, toggleProfileDrawer } = useUserContext();
  const { cid, comp } = useContext(FirebaseAuthContext);
  const hasVidCalling =  useMemo(() => Boolean(comp && (get(comp.data(), "features.video_calling"))), [comp]);
  const { openBookingDialog, bookingDialog } = useLiveSessions();
  const onClickAddBooking = () => openBookingDialog(undefined, userDoc)
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);

  const [openCallDialog, setCallDialog] = useState(false);
  const [openNotesDialog, setNotesDialog] = useState(false);
  const [lastActiveSession, setLastActiveSession] = useState(undefined);

  const openCallsDialog = useCallback(() => {
    showLoader();
    loadSessions().then((ques)=> {
      hideLoader();
      setCallDialog(true);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionDocs]);

  const openNoteDialog = (mode) => {
    return () => {
      if(mode === 'list') return toggleNotesDrawer();
      setNotesDialog(true);
    };
  };

  const closeNotesDialog = async(msg) => {
    if (msg === 'refresh'){
      showLoader();
      await loadNotes(false, null, null, true);
      hideLoader();
    }
    setNotesDialog(false);
  };

  useEffect(() => {
    if(!noteDocs.length){
      loadNotes();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(!sessionDocs.length || !!lastActiveSession) return;
    mostRecentSession(userDoc.uid, cid).then(res => {
      let foundSession = false;
      if(!res.empty){
        return res.forEach((doc => {
         return setLastActiveSession(doc);
        }))
      };
      sessionDocs.some((doc)=> {
        const data = doc.data();
        if(data.status === 'booked'){ setLastActiveSession(doc); foundSession = true ;return true;}
        return false;
      });
      if(!foundSession) loadSessions(true, [...(sessionDocs || [])].shift());
    });
  },[sessionDocs, lastActiveSession, loadSessions, userDoc.uid, cid, ]);

  useEffect(() => {
    // Find most recent session again if booking dialog closes
    if(!!bookingDialog || !cid || !userDoc || isEmpty(userDoc)) return;
    mostRecentSession(userDoc.uid, cid).then(res => {
      let foundSession = false;
      if(!res.empty){
        return res.forEach((doc => {
         return setLastActiveSession(doc);
        }))
      };
      sessionDocs.some((doc)=> {
        const data = doc.data();
        if(data.status === 'booked'){ setLastActiveSession(doc); foundSession = true ;return true;}
        return false;
      });
      if(!foundSession) loadSessions(true, [...(sessionDocs || [])].shift());
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingDialog]);

  return (
    <div>
      {!!openCallDialog && 
        <VideoCallsDrawer 
          open={openCallDialog} 
          onClose={() => setCallDialog(false)} 
          sessions={sessionDocs} 
          loadMore={() => loadSessions(true, [...sessionDocs].pop())} 
        />
      }

      {!!openNotesDialog && (
        <NoteDialog
          userId={docId}
          open={openNotesDialog}
          onClose={closeNotesDialog}
          loader={{ showLoader, hideLoader }}
        />
      )}
      <UserSummary profile={profile} openProfile={toggleProfileDrawer} userDoc={userDoc} openMessageDialog={openMessageDialog} />
      {/* <div className='fmt-15'><ActivityCard userDoc={userDoc} /></div>*/}
      <div className='fmt-15'><BMRInfoCard userId={docId} consultoria={userDoc && userDoc.consultoria}/></div>
      {cid === 'hibrido' && <div className='fmt-15'><GrpChatsCard userId={docId}/></div>}
      <div className='fmt-15'><LastPaymentCard userId={docId}/></div>
      {hasVidCalling && <div className='fmt-15'>
        <LiveSessionCard
          lastSession={lastActiveSession}
          openCallHistory={openCallsDialog}
          openBookingDialog={onClickAddBooking}
        />
      </div>}
      <div className='fmt-15'>
        <NotesCard className='fmt-15' openNoteDialog={openNoteDialog} lastNote={noteDocs[0]} />
      </div>
    </div>
  );
};
const NotesCard = ({ openNoteDialog, lastNote, ...rest }) => {
  const noteData = !!lastNote && lastNote.data();
  const classes = styles();
  return (
    <ParentCard title='Notes' rightAdornment={() => (
      <Tooltip title='View all notes'>
        <IconButton onClick={openNoteDialog('list')} size='small' edge={'end'}>
          <ExternalLinkIcon  />
        </IconButton>
      </Tooltip>
    )} {...rest}>
      {!!lastNote && !!noteData ? (
        <div className='fpt-15 fpx-15 cursor-pointer' onClick={openNoteDialog('list')}>
          <Typography 
            variant='body1' 
            className='font_13_500 fmb-10' 
            style={{whiteSpace: 'break-spaces', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {(!!noteData && truncate(noteData.text, {'length': 85})) || ''}
          </Typography>
          <Typography variant='body1' className='font_11_500' color='textSecondary'>
            {(!!noteData && !!noteData.ts && moment(noteData.ts.toDate()).fromNow())|| ''}
          </Typography>
        </div>
      ) : (
        null
      )}
      <div className='fpx-15'>
        <ClrdButton
          className={clsx("f-medium w-100 fpx-20 fmt-15 ", classes.actionButton )}
          variant="contained"
          onClick={openNoteDialog()}
        >
          <Typography className='font_13_500'>
            Add Notes
          </Typography>
        </ClrdButton>
      </div>
    </ParentCard>
  )
}
const LastPaymentInternal = ({ userId }) => {
  const [lastPaymentData, setLastPaymentData] = useState(null)
  const [loading, setLoading] = useState(true)
  const classes = styles();
  const fetchLastPayment = async () => {
    const snap = await firebase.firestore().collection(`user_profiles/${userId}/order`).orderBy('date', 'desc').limit(1).get()
    if(!snap.empty) {
      const data = snap.docs[0].data()
      setLastPaymentData(data)
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchLastPayment()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  if (loading) return <div className='d-flex flex-row justify-content-around mt-3'><CircularProgress/></div>;
  if (!lastPaymentData) return <div className='d-flex flex-row justify-content-around mt-3'><Typography className='font_13_500'>– N/A –</Typography></div>;
  const { date, pg, pack, iap, play, pgData  } = lastPaymentData;
  let price = get(pack, 'discounted_price') || get(pack, 'price'), pgurl;
  if(pg === 'stripe') {
    const stripe_cust = get(pgData, 'customer');
    if (stripe_cust) pgurl = `https://dashboard.stripe.com/customers/${stripe_cust}`;
    price = get(pgData, "amount_paid", get(pgData, "amount_received", 0)) / 100;
    if (!price && get(pgData, 'object') === 'checkout.session' && get(pgData, 'amount_total'))
      price = pgData.amount_total / 100;
  }
  if(pg === 'druo') price = Number(get(pgData, "amount", 0)) / 100;
  if(pg === 'paypal') price = Number(get(pgData, "amount.total", get(pgData, "amount.value", 0)));
  if(pg === 'razorpay') price = Number(get(pgData, "amount", get(pgData, "payment.amount", 0))) / 100;
  if(pg === 'mercado') price = Number(get(pgData, "transaction_amount", 0));
  if(pg === 'guru') price = Number(get(pgData, "payment.total") || get(pgData, "invoice.value") || 0);
  let amount = formatCurrency(price, null, pack.currency || false) + ' ';
  if (!price) amount = '';
  const isInApp = (iap || play)
  if(!isInApp && !price) amount = 'Trial ';
  if(pg === 'play' && pgData.paymentState === 2) amount = 'Trial ';
  const gateway = isInApp ? (iap ? 'Apple' : 'Google Play'): capitalize(pg)
  return (
    <div className='fpt-15 fpx-15'>
      <div className='d-flex align-items-center fmb-15' style={{ maxWidth: '90%' }}>
        <Typography>
          <MoneyIcon classes={{ root: classes.summaryIconMui }}/>
        </Typography>
        <Typography variant='body1' className='font_13_500 fml-10' noWrap>
          <span className='font-weight-600'>{amount}</span>{`via ${gateway}`}
          {pgurl && <Tooltip title={`View in ${gateway}`}>
            <IconButton component='a' href={pgurl} target='_blank' size='small' className='p-0 ml-1'>
              <ExternalLinkIcon  />
            </IconButton>
          </Tooltip>}
        </Typography>
      </div>
      <div className='d-flex align-items-center' style={{ maxWidth: '90%' }}>
        <Typography>
          <CalendarIcon classes={{ root: classes.summaryIconMui }} />
        </Typography>
        <Typography variant='body1' className='font_13_500 fml-10' color='textSecondary'>
          {moment(date).format('DD MMM YYYY, hh:mm a')}
        </Typography>
      </div>
    </div>
  )
}
const LastPaymentCard = ({ userId }) => {
  return (
    <ParentCard title='Last Payment'>
      <LastPaymentInternal userId={userId}/>
    </ParentCard>
  );
}
const CallDetails = ({ sessionData, ...rest}) => {
  if(!sessionData) return null;
  const startTime = !!sessionData && moment(sessionData.startDate);
  const endTime = !!sessionData && moment(sessionData.endDate).add(1, 'second');
  const slotStatus = !!sessionData && findBookingStatus(sessionData);
  const isClass = !!sessionData && !!sessionData?.class
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {status_text, status_color : statusColor} = getStatusAndColor(slotStatus, isClass);
  const classes = styles({bgColor: statusColor});
  const sessionNote = !!sessionData && !!sessionData.note && linkifyHtml(sessionData.note);
  return (
    <div className='fpx-15 fpt-15'>
      {!!sessionData && sessionData.note && <div
        className={clsx('font_13_500 fmb-15', classes.callNoteDiv)}
        dangerouslySetInnerHTML={{
          __html: truncate(sessionNote, {length: '80'})
        }}
      /> }
      <div className='d-flex justify-content-between align-items-center'>
        <Typography className='font_11_500' color='textSecondary'>
          {startTime.clone().format('DD MMM')}, {startTime.clone().format('hh:mm')} - {endTime.clone().format('hh:mm A')}
        </Typography>
        <div className={clsx(statusColor, classes.statusBadge, 'rounded')}>
          <Typography className='font_11_500 font-weight-bolder text-capitalize' >
            {status_text}
          </Typography>
        </div>
      </div>
    </div>
  )
};
const LiveSessionCard = ({ lastSession, openCallHistory, openBookingDialog, ...rest}) => {
  const sessionData = lastSession && structureAppointment(lastSession.data(), 0, {withTimestamp: true, includeCancelled: true });
  const classes = styles();
  return (
    <ParentCard title='Appointments'
      rightAdornment={() => (
        <Tooltip title='View All Sessions'>
          <IconButton onClick={openCallHistory} size='small' edge={'end'}>
            <ExternalLinkIcon  />
          </IconButton>
        </Tooltip>
      )}
     {...rest}>
      {!!sessionData && <CallDetails sessionData={sessionData}/>}
      <div className='fpx-15'>
        <ClrdButton
          className={clsx("f-medium w-100 fpx-20 fmt-15 ", classes.actionButton )}
          variant="contained"
          onClick={openBookingDialog}
        >
          <Typography className='font_13_500'>
            Book Live Session
          </Typography>
        </ClrdButton>
      </div>
    </ParentCard>
  )
};

const MinorCount = ({title, value, perc, precision = 0}) => {
  return (
    <div className='d-flex flex-column'>
      <Typography noWrap className='font_11_500 text-grey mb-3px'>{title}</Typography>
      <div className='d-flex flex-row align-items-baseline'>
        <Typography className='font_11_700 text-0d0d0d'>{formatNo(value, {precision: 0}, '-')}</Typography>
        <Typography className='font_11_500 text-0d0d0d'>&nbsp;g</Typography>
        <Typography className='font_11_700 text-0d0d0d'>&nbsp;•&nbsp;</Typography>
        <Typography className='font_11_700 text-0d0d0d'>{formatNo(perc, {precision: 0}, '-')}</Typography>
        <Typography className='font_11_500 text-0d0d0d'>&nbsp;%</Typography>
      </div>
    </div>
  );
};
const BMRInfoCard = ({ userId, consultoria = false }) => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [macros, setMacros] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchBMR = async () => {
    const snap = await firebase.firestore().doc(`user_profiles/${userId}/misc/bmr`).get()
    if (!snap.exists) return;
    const macros = snap.data().macros;
    if (!macros || !macros.calories) return null;
    const { calories: cals, protein: p, fat: f, carbs: c } = macros;
    macros.pperc = p * 400 / cals;
    macros.fperc = f * 900 / cals;
    macros.cperc = c * 400 / cals;
    setMacros(macros);
    setLoading(false);
  };
  useEffect(() => {
    fetchBMR()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  const refreshMacros = async () => {
    const uid = userId.split(':').pop();
    setLoading(true);
    refreshConsultoria(uid).then(res => {
      const { success, message } = res.data;
      if (!success) enqueueSnackbar(message || 'Something went wrong', {variant: 'error'});
      else return fetchBMR();
      setLoading(false);
    }).catch(err => {
      enqueueSnackbar('Something went wrong', {variant: 'error'});
      setLoading(false);
    });
  };
  if (!macros || !macros.calories) return null;
  if (loading) {
    return (
      <ParentCard title='Recommended Cals & Macros'>
        <div className='d-flex flex-row justify-content-around p-15'>
          <CircularProgress/>
        </div>
      </ParentCard>
    );
  }
  const { calories, protein: p, fat: f, carbs: c, pperc, fperc, cperc } = macros;
  return (
    <ParentCard title='Recommended Cals & Macros'>
      <div className='pt-15 px-15'>
        <div className='d-flex flex-row align-items-baseline'>
          <Typography className='font_13_700 text-0d0d0d'>{formatNo(calories, {precision: 0}, '-')}</Typography>
          <Typography className='font_13_500 text-0d0d0d'>&nbsp;Cals</Typography>
        </div>
        <div className='d-flex flex-row justify-content-between mt-15'>
          <MinorCount value={p} perc={pperc} title='Protein'/>
          <MinorCount value={f} perc={fperc} title='Fat'/>
          <MinorCount value={c} perc={cperc} title='Carbs'/>
        </div>
        {consultoria && <ClrdButton variant='contained' onClick={refreshMacros}
        className={clsx('f-medium w-100 px-20 mt-15', classes.actionButton )}
        >Re-Calculate</ClrdButton>}
      </div>
    </ParentCard>
  );
}

const GrpsInternal = ({ userId }) => {
  const [grps, setGrps] = useState(null)
  const [loading, setLoading] = useState(true)
  const fetchGrps = useCallback(() => {
    if (!userId) return;
    setLoading(true);
    firebase.firestore().doc(`user_profiles/${userId}/misc/group_part_of`)
      .get().then(snap => {
        if (!snap.exists) return null;
        const data = snap.data();
        const ids = [];
        Object.entries(data).forEach(([key, obj]) => {
          if (!obj) return;
          if (!obj.read) return;
          ids.push(key);
        });
        return ids;
      }).then((ids) => {
        if (!ids || !ids.length) return null; 
        return firebase.firestore().collection('groupChats')
          .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
          .get();
      }).then((res) => {
        if (!res.empty) {
          const grps = [];
          res.forEach(doc => {
            const { name, archive, deleted } = doc.data();
            if (archive || deleted) return;
            grps.push({id: doc.id, name: name});
          });
          setGrps(grps);
        }
        setLoading(false);
      }).catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [userId]);
  useEffect(() => {
    fetchGrps()
  }, [fetchGrps])
  if (loading) return <div className='d-flex flex-row justify-content-around mt-3'><CircularProgress/></div>;
  if (!grps) return <div className='d-flex flex-row justify-content-around mt-3'><Typography className='font_13_500'>– NONE –</Typography></div>;
  return (<div className='px-15 pt-15'>
    {grps.map(({id, name}) => {
      const path = `/messages/group-chats/${id}`;
      return (<Link key={id} className='font_13_500 cursor-pointer d-block' component={RLink} to={path}>{name}</Link>);
    })}
  </div>);
}
const GrpChatsCard = ({ userId }) => {
  return (
    <ParentCard title='Group Chats'>
      <GrpsInternal userId={userId}/>
    </ParentCard>
  );
}

const UserSummary = ({profile: { email, time_zone } = {}, openProfile, userDoc = {}, openMessageDialog=null }) => {
  const { enqueueSnackbar } = useSnackbar();
  const copyToClipboard = () => !!navigator && navigator.clipboard.writeText(email)
    .then(() => enqueueSnackbar('Copied to clipboard', {variant: 'success'}))
    .catch(()=>{ enqueueSnackbar('Unable to copy', {variant: 'error'}) })
  const classes = styles();
  const { nick_name, app_info_ios, app_info_android } = userDoc;
  const last_seen = getUserLastSeen(userDoc);
  return (
    <ParentCard title='Summary' rightAdornment={() => (
      <Tooltip title='View Client Profile'>
        <IconButton onClick={openProfile} size='small' edge={'end'}>
          <ExternalLinkIcon  />
        </IconButton>
      </Tooltip>
    )}>
      <div className='pt-15 px-15 flex-1 w-100'>
        {!!email && (
          <div className='d-flex align-items-center justify-content-between w-100' >
            <div className='d-flex align-items-center' style={{ maxWidth: '90%'}}>
              <Typography>
                <MailOutlined classes={{root: classes.summaryIconMui }} />
              </Typography>
              <Typography variant='body1' className='font_13_500 fml-10' noWrap>
                {email}
              </Typography>
            </div>
            <Tooltip title={`Copy ${email}`}>
              <IconButton onClick={copyToClipboard} className='m-n10'>
                <CopyIcon classes={{root: 'x-small'}} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {!!nick_name && (
          <div className='d-flex align-items-center justify-content-between fmt-15'>
            <div className='d-flex align-items-center' style={{ maxWidth: '90%'}}>
              <Typography classes={{root: classes.summaryIconMui }}>
                <i class='fas fa-at'/>
              </Typography>
              <Typography variant='body1' className='font_13_500 fml-10' noWrap>
                {nick_name}
              </Typography>
            </div>
          </div>
        )}
        {!!last_seen && (
          <div className='d-flex align-items-center justify-content-between fmt-15'>
            <div className='d-flex align-items-center'>
              <Typography>
                <VisibilityOutlined classes={{root: classes.summaryIconMui }} />
              </Typography>
              <Typography variant='body1' className='font_13_500 fml-10'>
                Last seen: {moment(last_seen).fromNow()}
              </Typography>
            </div>
          </div>
        )}
        {!!time_zone && (
          <div className='d-flex align-items-center justify-content-between fmt-15'>
            <div className='d-flex align-items-center'>
              <Typography>
                <PinDropOutlined classes={{root: classes.summaryIconMui }} />
              </Typography>
              <Typography variant='body1' className='font_13_500 fml-10'>
                {time_zone}
              </Typography>
            </div>
          </div>
        )}
        {/*
          <div className='d-flex align-items-center justify-content-between w-100 fmt-15'>
            <div className='d-flex align-items-center'>
              <Typography>
                <WearableIcon classes={{root: classes.summaryIconMui }} />
              </Typography>
              <Typography variant='body1' className='font_13_500 fml-10 text-capitalize text-break'>
              {!!connected_wearables && !!connected_wearables.length ? 
                `Wearable: ${connectedWearables}`
                  : 'Wearable'}
              </Typography>
            </div>
            {!connected_wearables || !connected_wearables.length ? (
              <ClrdButton color='primary' onClick={openMessage}>
                <Typography className='font_13_500'>
                  Ask To Connect
                </Typography>
              </ClrdButton>
            ): null}
          </div>
        */}
        {!!app_info_ios && app_info_ios['client-version'] && (
          <div className='d-flex align-items-center justify-content-between w-100 fmt-15'>
            <div className='d-flex align-items-center'>
              <Typography>
                <AppleIcon classes={{root: classes.summaryIconMui }} />
              </Typography>
              <Typography variant='body1' className='font_13_500 fml-10'>
                App Version: {app_info_ios['client-version']}
              </Typography>
            </div>
          </div>
        )}
        {!!app_info_android && !!app_info_android['client-version'] && (
          <div className='d-flex align-items-center justify-content-between w-100 fmt-15'>
            <div className='d-flex align-items-center'>
              <Typography>
                <AndroidIcon classes={{root: classes.summaryIconMui }} />
              </Typography>
              <Typography variant='body1' className='font_13_500 fml-10'>
                App Version: {app_info_android['client-version']}
              </Typography>
            </div>
          </div>
        )}
        <ClrdButton
          className={clsx("f-medium w-100 fpx-20 fmt-15", classes.actionButton)}
          variant="contained"
          onClick={openProfile}
        >
          <Typography className='font_13_500'>
            View Client Profile
          </Typography>
        </ClrdButton>
      </div>
    </ParentCard>
  )
};
export default RightSection;
