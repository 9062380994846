import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import { Chip, IconButton } from "@material-ui/core";
import PlayIcon from "fitbud/images/play_icon.svg"
import StopIcon from "fitbud/images/stop_record.svg"
import {secondsToMinutes} from "fitbud/utils/helpers";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  width: {
    width: "90%"
  }
});

const initialState = {
  audioPlayer: {
    state: 'pause', duration: 0, playbackRate: 1
  },
  progress: 0
};

const AudioPlayer = (props) => {
  const classes = useStyles();
  const ref = useRef();
  const rangeRef = useRef();
  const { src, duration, minified } = props;
  const [state, setState] = useState(initialState);
  const isReset = ref.current?.currentTime === 0;

  const toggleAudioState = () => {
    let audioPlayer = _.assign({}, state.audioPlayer);
    const audioEl = ref.current;

    if(audioPlayer.state === 'pause') {
      //Stop all other audio tracks
      var sounds = document.getElementsByTagName('audio');
      for(let i=0; i<sounds.length; i++) {
        // Skip current audio element
        if(sounds[i] === audioEl) continue;
        sounds[i].pause();
        // Reset audio element to start
        sounds[i].currentTime = 0;
      };
      audioPlayer.state = 'play';
      audioEl.play();
    } else {
      audioPlayer.state = 'pause';
      audioEl.pause();
    }
    setState((prev) => ({ ...prev, audioPlayer: _.assign({}, state.audioPlayer, audioPlayer) }));
  }

  const audioTimeUpdate = async () => {
    let audioPlayer = _.assign({}, state.audioPlayer),
        audioEl     = ref.current
    rangeRef.current.value = Math.floor(audioEl.currentTime);
    const rangeVal = (rangeRef.current.value - rangeRef.current.min) / (rangeRef.current.max - rangeRef.current.min);
    const percent = rangeVal * 100;
    rangeRef.current.style.backgroundImage = '-webkit-gradient(linear, left top, right top, ' +
    'color-stop(' + percent + '%, #000000), ' +
    'color-stop(' + percent + '%, #e1e1e1)' +
    ')'
    audioPlayer.duration = duration;
    setState({audioPlayer: _.assign({}, state.audioPlayer, audioPlayer), progress: Math.floor(audioEl.currentTime)});
  }

  const rangeChange = () => {
    const audioEl = ref.current;
    audioEl.currentTime = rangeRef.current.value;
  }

  const handlePlaybackRate = () => {
    const audioPlayer = { ...state.audioPlayer };
    const audioEl = ref.current;

    audioPlayer.playbackRate = audioPlayer.playbackRate === 2 ? 1 : audioPlayer.playbackRate + 0.5;
    audioEl.playbackRate = audioPlayer.playbackRate;
    setState((prev) => ({ ...prev, audioPlayer }));
  };
  
  const { audioPlayer } = state;
  const totalAudioDuration = secondsToMinutes(duration);
  const _progress = secondsToMinutes(state.progress);


  useEffect(() => {
    const audioEl = ref.current;
    if(isReset) {
      audioEl.playbackRate = 1;
      setState((prev) => ({...prev, audioPlayer: { ...prev.audioPlayer, playbackRate: 1 }}));
    }
  }, [isReset])

  useEffect(() => {
    let audioEl = ref.current;
    function handleEnded() {
      if (rangeRef && rangeRef.current) {
        rangeRef.current.style.backgroundImage = '';
        rangeRef.current.value = 0;
      }
      audioEl.playbackRate = 1;
      setState((prev) => ({ audioPlayer: { ...prev.audioPlayer, state: 'pause', playbackRate: 1 }, progress: 0}));
    }
    audioEl.addEventListener('ended', handleEnded);

    return () => audioEl.removeEventListener('ended', handleEnded);
  }, []);

  useEffect(() => {
    let audioEl = ref.current;
    function handlePause(event) {
      // get current time of the current audio player and zero for other audio players
      const currentTime = event.target.currentTime;

      // set current time to reset the audio player other than the current one
      setState((prev) => ({
        audioPlayer: {
          ...prev.audioPlayer,
          state: 'pause',
        },
        progress: Math.floor(currentTime),
      }));
    }
    // Add listener to handle pause event when paused by another audio player on the same page
    audioEl.addEventListener('pause', handlePause);
    return () => audioEl.removeEventListener('pause', handlePause);
  }, []);

  if(!minified){
    return (
      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
        <audio ref={ref} onTimeUpdate={audioTimeUpdate}>
          <source src={src} type="audio/mp3" />
        </audio>
          <IconButton onClick={toggleAudioState}>
            <img alt="Play Recording" src={audioPlayer.state === "pause" ? PlayIcon : StopIcon} />
          </IconButton>
          <div className={`d-flex justify-content-center align-items-center fmt-10 ${classes.width}`}>
            <span className="font_13_500 text-muted">{_progress}</span>
            <input className={`audioRange fml-20 fmr-20 ${classes.width}`} ref={rangeRef} type="range" min="0" max={audioPlayer.duration} step="1" onChange={rangeChange}/>
            <span className="font_13_500 text-muted">{totalAudioDuration}</span>
            <Chip
              onClick={handlePlaybackRate}
              label={audioPlayer.playbackRate + 'x'}
              classes={{label: "p-0 font_13_600 text-center"}}
              className="h-auto ml-10 px-10 py-2px rounded-pill flex-shrink-0"
              style={{ backgroundColor: '#DADCDE', width: '43.5px' }}
            />
          </div>
      </div>
    );
  }
  return (
    <div className="w-100 d-flex align-items-center justify-content-center fp-5">
      <audio ref={ref} onTimeUpdate={audioTimeUpdate}>
        <source src={src} type="audio/mp3" />
      </audio>
        <IconButton onClick={toggleAudioState} style={{ marginLeft: '-16px'}} classes={{ root: "p-8" }}>
          <img alt="Play Recording" src={audioPlayer.state === "pause" ? PlayIcon : StopIcon} style={{ width: '44px' }}/>
        </IconButton>
        <div className={`d-flex justify-content-center align-items-center ${classes.width}`}>
          <span className="font_13_500 text-muted">{_progress}</span>
          <input className={`audioRange fml-10 fmr-10 rounded-20 ${classes.width}`} ref={rangeRef} type="range" min="0" max={audioPlayer.duration} step="1" onChange={rangeChange} />
          <span className="font_13_500 text-muted">{totalAudioDuration}</span>
          <Chip
            onClick={handlePlaybackRate}
            label={audioPlayer.playbackRate + 'x'}
            classes={{label: "p-0 font_13_600 text-center "}}
            className="h-auto ml-10 px-10 py-2px rounded-pill flex-shrink-0"
            style={{ backgroundColor: '#DADCDE', width: '43.5px' }}
          />
        </div>
    </div>
  )
}

export default AudioPlayer;