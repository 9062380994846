import React,{useContext,useMemo} from 'react';
import {
    changeDate,
    getHeightWidthUisngFormula
  } from "fitbud/utils/helpers";
  import moment from "moment";
  import {
    Typography,
    IconButton,
    ListItem,
    ListItemText,
    makeStyles,
  } from "@material-ui/core";
  import {ShowDate,AttachmentList} from './chatView';
import clsx from "clsx";
import useTrainers from 'fitbud/hooks/useTrainers';
import Linkify from "linkifyjs/react";
import ImgAspectFill from "fitbud/components/imgAspectHOC";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import timer from "fitbud/images/clock.svg";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import _ from "lodash";
import TargetMessage from './targetMessage';
import ReactionBubble from './reactionBubble';

const useStyles = makeStyles(theme => ({
  bubble: {
    width: '100%',
    whiteSpace: "pre-wrap"
  },
  timeLeft: {
    justifyContent: "start",
    color: "#818181"
  },
  timeRight: {
    justifyContent: "flex-end",
    color: "#818181",
  },
  staffName:{
    marginLeft: "auto",
    justifyContent: "flex-end",
    color: "#818181",
    textTransform: "capitalize"
  },

  leftMsg: {
    width: "max-content",
    maxWidth: "50%",
    minWidth: "100px",
    wordWrap: "break-word",
    marginRight: "auto",
    marginLeft: "30px",
    position: "relative",
    background: "#FFF",
    border: '1px solid #D3D9DB',
    borderRadius: "0px 10px 10px 10px",
    padding: "9.5px 20px"
  },

  rightMsg: {
    width: "max-content",
    maxWidth: "calc(50% + 50px)",
    minWidth: "100px",
    wordWrap: "break-word",
    marginLeft: "auto",
    borderRadius: "10px 0px 10px 10px",
    background: "#F0F3F5",
    border: '1px solid #F0F3F5',
    padding: "9.5px 20px"
  },
  mediaRight: {
    width: "max-content",
    padding: 0,
    marginLeft: "auto",
    border: "none"
  },
  mediaLeft: {
    width: "max-content",
    padding: 0,
    paddingLeft: "30px",
    marginRight: "auto",
    border: "none"
  },
  replyBody: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    textOverflow: "ellipsis",
    marginBottom: 0,
    whiteSpace: "normal"
  },
  replyBar: {width: 2, borderRadius: 1},
  replyBubble: { backgroundColor: "#0000000F", cursor: "pointer" },
  replyContainer: {
    display: "flex",
    maxWidth: "50%",
    columnGap: "10px",
    marginBottom: "10px"
  },
  replyRight: {
    marginRight: "30px",
    flexDirection: "row-reverse"
  },
  replyLeft: {
    marginLeft: "30px",
  }
}));

 
const Message=({msg,propsId,ext,handleOpenMessageOption,index,messages, scrollToMessage, user, viewWidth})=>{
    const classes=useStyles();
    const { cid } = useContext(FirebaseAuthContext);
    const { getAuthor } = useTrainers();
    let msgData = msg.data({ serverTimestamps: "estimate" });
    const staff=useMemo(()=>{
      if (!msgData.staffId) return {};
      return getAuthor(msgData.staffId, { dm: true });
    },[msgData, getAuthor]);
    let timestamp_current = msgData.timestamp;
    let timestamp_previous = index !== 0
        ? messages[index - 1].data().timestamp
        : msgData.timestamp;
    let date1 = moment(timestamp_current && timestamp_current.toMillis()).local();
    let date2 = moment(timestamp_previous && timestamp_previous.toMillis()).local();
    let time = moment(date1).format("hh:mm a");
    let height, width;
    if (msgData.media) {
        const aspect = msgData.media.aspect || 1;
        let { height_new, width_new } = getHeightWidthUisngFormula(viewWidth,aspect);
        height = height_new;
        width = width_new;
    }
    const isCompanyMsg=(cid===msgData.authorId)|| !!msgData.staffId;
    let marginLeft = isCompanyMsg ? "auto" : "none";
    const image_base_path = _.get(msgData, 'media.base_path', false);

    const targetMessageUserName = useMemo(() => {
      const replyData = msgData.reply;
      if(!replyData) return null;

      if (replyData.staffId)
        return getAuthor(replyData.staffId, { reply: true }).name;

      return (user && user.profile && user.profile.name) || null;
    },[getAuthor, msgData.reply, user]);

    return (
        <div key={msg.id} id={msg.id} className={clsx("fmy-20 d-flex flex-column",
            isCompanyMsg ? "align-items-end": "align-items-start")}>
            {changeDate(date1, date2) && <ShowDate date={date1} />}
            {msgData.reply && !msgData.deleted && (
              <TargetMessage 
                className={isCompanyMsg ? classes.replyRight : classes.replyLeft} 
                onClick={() => scrollToMessage(msgData.reply.replyId)}
                msg={msgData.reply}
                propsId={propsId}
                userName={targetMessageUserName}
              />
            )}
            <div className="d-flex w-100 pr-30">
              <div className={classes.bubble} >
                <ListItem divider component="div"
                  className={clsx(
                    "msgBubble",
                    msgData.type === "image" && !msgData.deleted
                      ? isCompanyMsg
                        ? classes.mediaRight
                        : classes.mediaLeft
                      : isCompanyMsg
                      ? classes.rightMsg
                      : classes.leftMsg
                  )}
                  style={{
                    ...(!!msgData.staffId && staff.border)? {border:`1px solid ${staff.color}`}:null,
                    transition: "background-color 0.25s"
                  }}
                >
                  {msgData.react && !msgData.deleted && (
                        <ReactionBubble 
                          reactions={msgData.react} 
                          isRightSide={isCompanyMsg}
                        />
                      )}
                  {msgData.deleted ?  (
                    <ListItemText className='font-italic text-muted'>
                      This message has been deleted
                    </ListItemText>
                  ) : (
                        <>
                          {msgData.type === "text" && 
                            <ListItemText >
                              <Linkify>{msgData.body}</Linkify>
                            </ListItemText>
                          }
                          {(msgData.type === "attachment" || msgData.type === "audio" || msgData.type === "video") && (
                            <AttachmentList id={propsId} attachmentData={msgData} key={msgData.attachment.identifier} />
                          )}
                          {(<div style={{ height: height, width: width, marginLeft: marginLeft }}>
                              {msgData.media && (
                                <ImgAspectFill
                                  authorId={msgData.authorId}
                                  authUserId={cid}
                                  targetHeight={height}
                                  targetWidth={width}
                                  identifier={msgData.media.identifier}
                                  completed={msgData.media.completed}
                                  aspect={msgData.media.aspect || 1}
                                  imageData={msgData.media.imageData}
                                  storageFolderRef={!!image_base_path ? `${image_base_path}/chats` :`user_profiles/${propsId}/chats`}
                                  bucketName="chat"
                                  style={{ width: "100%", borderRadius: "10px" }}
                                  alt={"Chat Image"}
                                  ext={ext}
                                />
                              )}
                            </div>)}
                        </>)}
                </ListItem>
                <div className={clsx("d-flex align-items-center ml-30 flex-row-reverse",!!isCompanyMsg?'':'justify-content-end')}>
                  {!msgData.deleted &&
                    <IconButton size='small' edge='end' onClick={(e) => handleOpenMessageOption(e, msg)} data-id={msg.id}>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  <Typography className={clsx(
                    isCompanyMsg ? (clsx(classes.timeRight) ) : classes.timeLeft, 
                    'd-flex font_11_500',
                    msgData.deleted || cid !== msgData.authorId ? "py-5" : "",
                    
                  )}>
                    <span>{time}</span>
                    {isCompanyMsg && (msg.metadata.hasPendingWrites ? (
                        <img src={timer} className="fml-10" alt="" />
                      ) : "" )}
                  </Typography>
                {staff && staff.name && <Typography className={clsx("d-flex font_11_500",classes.staffName)} style={{color:staff.color}}>{staff.name} • </Typography>}
                </div>
              </div>
            </div>
        </div>
        )}

export default Message;
