import * as Sentry from '@sentry/browser';
import firebase from "fitbud/firebase";
import { Conversion } from "fitbud/providers/conversion";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { oprtnlDateFormat } from "fitbud/utils/constants";
import { userPlanPath } from "fitbud/utils/helpers";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useState, useMemo } from "react";
import { useUserContext } from "./detail";
import {
  convertData, fetchActivity, fetchComplianceConfig, fetchNutrition,
  fetchWater, fetchWorkouts, parseProgress, parseTarget,
  sumActivityData, sumNutritionData, sumWaterData,
  sumWorkoutData
} from "./progress/helpers";

export const ProgressTrendsContext = React.createContext({});
export const useProgressTrendsContext = () => useContext(ProgressTrendsContext);
  
// Process data based on selected week / month
const calculateCompliance = ({selectedPeriod, progress, target, planStartDate, planTotalDuration}, convertor)=> {
  if(!progress || !target) return;
  try {
    const planStartMoment = moment(planStartDate, oprtnlDateFormat);
    let start = moment(selectedPeriod.startDate).diff(planStartMoment.clone(), 'days');
    let end = start + moment(selectedPeriod.endDate).diff(moment(selectedPeriod.startDate), 'day') + 1;
    if(end >= planTotalDuration) end = planTotalDuration;
    const totalDays =  end - start;
    const currentDay = moment().diff(planStartMoment.clone(), 'days');
    let _workout = {logs:[], totalEntries:0, totalDays};
    let _activity = {logs:[], totalEntries:0, totalDays};
    let _nutrition = {logs:[], totalEntries:0, totalDays};
    let _water = {logs:[], totalEntries:0, totalDays};
    for(let i = start; i < end ; i++){
      if(i > currentDay) break;
      const key = `day_${i+1}`;
      const date = moment(planStartDate, oprtnlDateFormat).add(i, 'days').format(oprtnlDateFormat);
      const {workout, activity, nutrition, water} = progress;
      const {workout: workout_target, activity: activity_target, nutrition: nutrition_target, water: water_target} = target;
      _workout = sumWorkoutData(date, _workout, (workout && workout[key]) || undefined, (workout_target &&  workout_target[key]) || undefined);
      _nutrition = sumNutritionData(date, _nutrition, (nutrition && nutrition[key]) || undefined, (nutrition_target &&  nutrition_target[key]) || undefined);
      _water = sumWaterData(date, _water, (water && water[key]) || undefined, (water_target &&  water_target[key]) || undefined, convertor);
      _activity = sumActivityData(date, _activity, (activity && activity[key]) || undefined, (activity_target &&  activity_target[key]) || undefined, convertor);
    };
    return convertData({ workout:_workout, nutrition: _nutrition, activity: _activity, water: _water }, convertor);
  } catch(err) {
    Sentry.captureException(err);
    console.log(err)
  }
};

// Load Data from firebase and arrange in progress and target separately
const fetchUserProgress = async({aplan, planTotalDuration, planStartDate}, comp)=> {
  // setLoading(true);
  // if(!aplan) return setLoading(false);
  let promises = [ fetchWorkouts(aplan), fetchWater(aplan), fetchActivity(aplan), fetchNutrition(aplan) ];
  let complianceMark = _.get(comp && comp.data(), 'complianceMark', false);
  try{
    if(!complianceMark) promises.push(fetchComplianceConfig()) // fetch from appConfig if not in company
    return Promise.all(promises).then((results)=> {
      let _progress = {};
      let _target = {};
      let _complianceMark = complianceMark;
      results.forEach(({type, progress: data_progress, target: data_target, ...rest}) => {
        if(type === 'complianceMark' && !complianceMark && !!rest.res && !!rest.res.exists){
          const { complianceMark: _mark } = rest.res.data();
          _complianceMark = _mark || false;
        }
        const targetData = data_target && parseTarget(data_target.data(), planTotalDuration, type);
        const progressData = data_progress && parseProgress(data_progress.data(), type, planStartDate);
        _progress = _progress ? {..._progress, [type]: {...progressData } } : {[type]: {...progressData}};
        _target =  _target ? {..._target, [type]: {...targetData} } : {[type]: {...targetData}};
      });
      return {progress:_progress, target:_target, complianceConfig: _complianceMark};
    });
  } catch(err){
    Sentry.captureException(err);
    console.log(err)
  }
};
const fetchMeasurements = async ({planPath}, measurableTags, convertor, measurementsTarget ) => {
  try {
    let out = {};
    const doc = await firebase.firestore().doc(`${planPath}/aggregate/measurements`).get();
    if(!doc.exists) return { ...out };
    const _measurementsAggregate = doc.data();
    if(!_measurementsAggregate) return;
    let keys = Object.keys(_measurementsAggregate);
    for(let i=0; i < keys.length; i++){
      const date = keys[i];
      const dayData = _measurementsAggregate[date];
      const measuredValues = Object.keys(dayData);
      if(!measuredValues || !measuredValues.length) continue;
      for(let j=0; j < measuredValues.length ; j++){
        let measureType = measuredValues[j];
        const measuredValue = dayData[measureType];
        const {tracking, unit_type} = measurableTags[measureType] || {}; 
        if(!tracking) continue;
        let { target } = measurementsTarget[measureType] || {};
        measureType = measureType.toLowerCase();
        if(measuredValue){
          const value = convertor.getDisplayValue(unit_type, measuredValue);
          let logs = out[measureType] && out[measureType].logs ? [...out[measureType].logs, { date, value }]  : [{ date, value }]
          logs=logs.sort((a,b)=>moment(b.date, oprtnlDateFormat).unix() - moment(a.date, oprtnlDateFormat).unix());
          target = convertor.getDisplayValue(unit_type, target);
          out[measureType] = out[measureType] ? {...out[measureType], logs, target} : {unit: convertor.getDisplayUnit(unit_type), logs, target};
        }
      }
  }
  return {...out};
  } catch(err){
    Sentry.captureException(err);
    console.log(err)
  }
};

const fetchExerciseData = async(planPath, convertor) => {
  try {
    let out = {};
    const doc = await firebase.firestore().doc(`${planPath}/aggregate/exercise`).get();
    if(!doc.exists) return null;
    const data = doc.data();
    if(!data) return null;
    const exercises = Object.keys(data);
    exercises.forEach((e) => {
      const id=e.split("/")[e.split("/").length-1].toLowerCase();
      const exData = data[e];
      Object.keys(exData).forEach(key=>{
        if(['bookmark','custom','documentId','name', 'rm'].includes(key)) return;
        if(!exData[key]) return;
        const useMaxVal=['reps_w_weight','reps','duration'].includes(key);
        let logs=Object.keys(exData[key]).map(l=>{
          const _data = exData[key][l];
          const {value1,value2,s_type} = _data || {};
          const maxVal1 = useMaxVal ? _data[`max_${key.split("_")[0]}`] : 0;
          const obj={
            date:l,
            value:convertor.getDisplayValue(key, value1 || maxVal1),
            unit:convertor.getDisplayUnit(key.split("_")[0]),
          }
          if((!!s_type && s_type!=="none")||(useMaxVal && !!_data["max_weight"])){
            const maxVal2 =  useMaxVal?_data["max_weight"] :0;
            obj["secondary"]=!!s_type?s_type:"weight";
            obj['value2']=convertor.getDisplayValue(obj["secondary"], value2 || maxVal2);
            obj['unit2']=convertor.getDisplayUnit(obj["secondary"]);
          }
          if(key==="reps_w_weight"){
            //making weight as primary
            const {value,value2,unit,unit2}=obj;
            obj['secondary']="reps";
            obj['value']=value2;
            obj['value2']=value;
            obj['unit']=unit2;
            obj['unit2']=unit;
          }
          //discarding an invalid log:
          if(key==="reps_w_weight"){
            if(!obj.value && !obj.value2) return null;
          }
          else{
            //all other cases
            if(!obj.value) return null;
          }
          return({...obj});
        });
        logs=logs.filter(i=>!!i).sort((a, b)=> moment(b.date, oprtnlDateFormat).unix() - moment(a.date, oprtnlDateFormat).unix());
        if(!logs.length) return;
        const primary=key==="reps_w_weight"?"weight":key;
        out[`${id}_${key}`]={
          name:exData.name,
          primary:primary,
          logs:[...logs],
          key:`${id}_${key}`,
          unit:convertor.getDisplayUnit(primary)
        }
      });
    });
    return {...out};
  } catch(err){
    Sentry.captureException(err);
    console.log(err)
  }
};

const ProgressTrendsProvider = (props) => {
  const { planStartDate, planTotalDuration, aplan, userId, measurableTags, userDoc } = useUserContext();
  const { comp } = useContext(FirebaseAuthContext);
  const { convertor } = useContext(Conversion);
  const [isProgressLoading, setLoading] = useState(true);
  const compChkinConf = useMemo(() => {
    if (!comp || !comp.exists) return null;
    const { checkin_config } = comp.data();
    return checkin_config;
  }, [comp]);
  const { measurements: measurementsTarget } = userDoc || {};

  //  **** Structure / Fetch all data from firebase ****
  const [progress, setProgress] = useState(false);
  const [target, setTarget] = useState(false);
  const [complianceConfig, setComplianceConfig] = useState(false);
  const [measurementsAggregate, setMeasurementsAggregate] = useState(false);
  const [exerciseData, setExerciseData] = useState(false);

  const loadAggMeasurementsData = useCallback(async(_measurableTags=measurableTags) => {
    if(!aplan) return false;
    const data = await fetchMeasurements({planPath: userPlanPath(userId, aplan), }, _measurableTags, convertor, measurementsTarget);
    setMeasurementsAggregate({...data});
  },[aplan, convertor, measurableTags, measurementsTarget, userId]);

  const loadProgressData = useCallback(async() => {
    if(!!progress || !!target || !aplan) return;
    setLoading(true)
    const { progress: _progress, target: _target, complianceConfig: _complianceConfig } =
    await fetchUserProgress({aplan, planTotalDuration, planStartDate}, comp);
    setProgress({..._progress});setTarget({..._target});setComplianceConfig({..._complianceConfig});
    setLoading(false);
  }, [aplan, comp, planStartDate, planTotalDuration, progress, target]);

  const calculateProgress = useCallback((selectedPeriod) => 
    calculateCompliance({selectedPeriod, progress, target, planStartDate, planTotalDuration}, convertor), 
    [convertor, planStartDate, planTotalDuration, progress, target]);

  const loadExerciseData = useCallback(async() => {
    if(!!exerciseData) return;
    const data = await fetchExerciseData(userPlanPath(userId, aplan), convertor);
    setExerciseData(data);
  },[aplan, convertor, exerciseData, userId]);
  
  const values = {
    complianceConfig, isProgressLoading, measurementsAggregate, measurableTags, progress, exerciseData,
    calculateProgress, loadProgressData, loadAggMeasurementsData, loadExerciseData, compChkinConf,
  };

  return (
    <ProgressTrendsContext.Provider value={{...values}}>
      {props.children}
    </ProgressTrendsContext.Provider>
  );
}
export default ProgressTrendsProvider;
