import {cloneDeep, merge} from 'lodash';
import { LANDSCAPE_ASPECT, LANDSCAPE_LARGE, PORTRAIT_ASPECT, PORTRAIT_RESOURCE_ASPECT, PORTRAIT_SMALL, SQUARE_ASPECT, SQUARE_SMALL } from './helper';
const DEFAULT_ACCENT_COLOR = '#6F8099';

export const GROUP_ENUM={
  group:true,
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: SQUARE_SMALL,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm:SQUARE_ASPECT,
    },
    alignment: 'left',
  },
  // hidden: true,
};
export const circle_common_item_config={
  alignment:'center',
  image_shape:'circle',
  type:'text_below_image'
}
export const CIRCLE_GROUP_ENUM=cloneDeep(
  merge({},GROUP_ENUM,{
    common_item_config:{
      ...circle_common_item_config
    },
    columns:{
      sm:3.5
    }
  })
);
export const PILL_GROUP_ENUM=cloneDeep(
  merge({},GROUP_ENUM,{
    common_item_config:{
      type:'pill'
    }
  }))

const PROGRAMS_WORKOUT = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: LANDSCAPE_LARGE,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_over_image',
    image_aspect: {
      sm: LANDSCAPE_ASPECT,
    },
    alignment: 'center',
  },
  hidden: true,
};

const MEAL_PLANS_NUTRITION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: LANDSCAPE_LARGE,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_over_image',
    image_aspect: {
      sm: LANDSCAPE_ASPECT,
    },
    alignment: 'center',
  },
  hidden: true,
};

const ON_DEMAND_WORKOUT = {
  type: 'grid',
  all: true,
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: SQUARE_SMALL,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: SQUARE_ASPECT,
    },
    alignment: 'left',
  },
  hidden: true,
};
const ON_DEMAND_INSIGHTS = {
  type: 'grid',
  all: true,
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  hidden: true,
  columns: {
    sm: PORTRAIT_SMALL,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: PORTRAIT_RESOURCE_ASPECT,
    },
    alignment: 'left',
  },
};

const ON_DEMAND_NUTRITION = {
  type: 'grid',
  all: true,
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: SQUARE_SMALL,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: SQUARE_ASPECT,
    },
    alignment: 'left',
  },
  hidden: true,
};

const ALL_COLLECTION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: SQUARE_SMALL,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: SQUARE_ASPECT,
    },
    alignment: 'left',
  },
  hidden: true,
};
const NUTRITION_COLLECTION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: SQUARE_SMALL,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: SQUARE_ASPECT,
    },
    alignment: 'left',
  },
  hidden: true,
};
const RESOURCE_COLLECTION = {
  type: 'grid',
  direction: 'horizontal',
  mergeable: true,
  accent_color: DEFAULT_ACCENT_COLOR,
  draws_accent: true,
  columns: {
    sm: PORTRAIT_SMALL,
    md: 3.3,
    lg: 4.4,
  },
  kind: 'with_space',
  background: '#ffffff',
  common_item_config: {
    stroke: true,
    image_shape: 'rounded',
    type: 'text_below_image',
    image_aspect: {
      sm: PORTRAIT_RESOURCE_ASPECT,
    },
    alignment: 'left',
  },
  hidden: true,
};

const WORKOUT_SPACE = {
  accent_color: DEFAULT_ACCENT_COLOR,
  type: 'space',
  draws_accent: true,
  hidden: true,
};
const COLLECTION_SPACE = {
  accent_color: DEFAULT_ACCENT_COLOR,
  type: 'space',
  draws_accent: true,
  hidden: true,
};

//deprecated:
const NUTRITION_SPACE = {
  accent_color: DEFAULT_ACCENT_COLOR,
  type: 'space',
  draws_accent: true,
  hidden: true,
};

const CURATED_COLLECTION_CONFIG = {
  columns: 2.2,
  stroke: true,
  image_shape: "rounded",
  style: "text_below_image",
  image_aspect: 1,
  alignment: "left",
};

export {
  PROGRAMS_WORKOUT,
  MEAL_PLANS_NUTRITION,
  ON_DEMAND_WORKOUT,
  ON_DEMAND_NUTRITION,
  ON_DEMAND_INSIGHTS,
  ALL_COLLECTION,
  RESOURCE_COLLECTION,
  NUTRITION_COLLECTION,
  COLLECTION_SPACE,
  NUTRITION_SPACE,
  WORKOUT_SPACE,
  CURATED_COLLECTION_CONFIG
};

export const PRESENTATIONS={
  grid:{label:'Photos',value:'text_below_image'},
  pill:{label:'Tags',value:'pill'},
  circle:{label:'Round Photos',value:'circle'},
}

