import _ from "lodash";
import React, { useContext } from "react";
import * as Sentry from '@sentry/browser';
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import update from "immutability-helper";
import firebase from "fitbud/firebase";
import { isOnline } from "fitbud/utils/helpers";
import { uploadFile } from "fitbud/utils/services";
import woRepo from "fitbud/repo/workouts";
import cardioRdxFns from "fitbud/redux/cardio";
import CardioForm from "./cardioForm";
import Details from "./details";
import woCardioRdxFns from "fitbud/redux/wocardio";
import appRdxFns from "fitbud/redux/app";
import {
  ERROR_TITLE,
  ERROR_REF_NAME,
  ERROR_DESCRIPTION,
  ERROR_CARIO_DUR,
  WORKOUTS_STORAGE_FILE_PATH,
  DEFAULT_ERROR,
  DRAFT_STATES,
  PUBLISHED,
  DRAFT,
  OFFLINE_ERROR,
  FETCH_ERROR,
  COMMON_ANALYTICS_PROPS
} from "fitbud/utils/constants";
import CircularLoader from "fitbud/components/CircularLoader";
import PageNotFound from "fitbud/views/pageNotFound";
import {FirebaseAuthContext} from "fitbud/providers/firebase-auth";
import uuidv4 from "uuid/v4";
import {calculatePublishStatus} from "fitbud/utils/catalog";
import {Conversion} from "fitbud/providers/conversion";
import LibraryConfirmation from "fitbud/views/workouts/components/libraryConfirmation";

const _default = {
  title: "",
  ref_name: "",
  type: "cardio",
  sub_type: "liss",
  desc: "",
  thumbnail: "",
  target_hr: [],
  duration: 0
};
const durationErrorMsg = "Duration and Distance - both cannot be empty. Please enter a value for at least one of them."
const DUPLICATE_REF_PREFIX = "Edited copy of";

class CardioInfo extends React.Component {
  constructor(props) {
    super(props);
    let defaultDoc = props.scheduleDoc
      ? _.cloneDeep({ ...props.doc, ...props.scheduleDoc })
      : props.doc;
    this.state = {
      loader: true,
      isValidId: true,
      doc: defaultDoc,
      oldDoc: props.doc,
      draftDoc:{},
      publishedDoc:{},
      errors: {},
      editMode: false,
      equipmentsData: [],
      muscleGroups: [],
      sidesData: [],
      showingDoc: PUBLISHED,
      isLibraryConfirmationOpen: false
    };
  }
  static contextType = FirebaseAuthContext;

  get docId() {
    return this.props.id;
  }

  get isNew() {
    return this.props.id === "new";
  }
  get draftFeature(){
    const {comp} = this.context;
    if(_.isUndefined(this.props.draftFeature)){
      return !!comp.data().features.draft;
    }
    return this.props.draftFeature;
  }
  get isDraftAvailable() {
    return !!_.get(this.state, "publishedDoc.publish_status",PUBLISHED).includes('draft');
  }

  handleChange = e => {
    let newState;
    let errors = {};

    if (e.target.type === "number") {
      e.target.value = Number(e.target.value);
    }

    const matches = e.target.name.split("-");
    if (matches.length > 1) {
      const index = matches[1];
      const key = matches[0];
      const value = Number(e.target.value);
      
      if (!!value && (value < 60 || value > 200)) {
        errors.target_hr = [];
        errors.target_hr[index] = ERROR_CARIO_DUR;
      }

      newState = update(this.state, {
        doc: {
          [key]: {
            [index]: { $set: Number(e.target.value) }
          }
        }
      });
    } else {
      const key = e.target.id || e.target.name;

      let value = e.target.value;
      if (e.target.type === "number") {
        value = Number(e.target.value);
      }

      if (e.target.name === "distance") {
        value = this.props.convertor.getDBValue('distance', value);
      }
      if(e.target.name === 'target_cals'){
        value = !!value ? Number(value) : firebase.firestore.FieldValue.delete();
      }
      newState = update(this.state, {
        doc: {
          [key]: {
            $set: value
          }
        }
      });

      if (e.target.name === "sub_type" && value === "hiit") {
        newState = update(newState, {
          doc: {
            $unset: ["target_hr"]
          }
        });
        newState = update(newState, {
          doc: {
            high_intensity: {
              $set: {
                duration:40,
                target_hr: [],
                title: "High Intensity",
              }
            },
            steady_state: {
              $set: {
                duration:20,
                target_hr: [],
                title: "Steady State"
              }
            },
            cool_down: {
              $set: {
                durationv2: 120,
                duration: 120,
                title: "Cool Down"
              }
            },
            warm_up: {
              $set: {duration:180, durationv2: 180, title: "Warm Up"}
            },
            rounds: {
              $set: 10
            },
            duration: {
              $set: 30
            }
          }
        });
      } else if (e.target.name === "sub_type" && value === "liss") {
        newState = update(newState, {
          doc: {
            $unset: [
              "high_intensity",
              "steady_state",
              "cool_down",
              "warm_up",
              "rounds"
            ]
          }
        });
        newState = update(newState, {
          doc: {
            target_hr: {
              $set: []
            },
            duration: {
              $set: 0
            }
          }
        });
      }

      if (newState.doc.rounds !== undefined) {
        const totalDuration = this.calculateDuration(newState.doc);

        newState = update(newState, {
          doc: {
            duration: {
              $set: totalDuration
            }
          }
        });
      }
    }

    this.setState({ ...newState, errors });
  };

  handleRanges = (name, title , e) => {
    let newState;
    let errors = {};

    if (e.target.type === "number") {
      e.target.value = Number(e.target.value);
    }

    if (e.target.name === "target_hr") {
      const [minHR, maxHR] = e.target.value;
      if ((!!minHR && (minHR < 60 || minHR > 200 )) || (!!maxHR && (maxHR < 60 || maxHR > 200))) {
        errors[name] = {};
        errors[name].target_hr = [];
        errors[name].target_hr[(minHR < 60 || minHR > 200) ? 0 : 1] = ERROR_CARIO_DUR;
      }
      newState = update(this.state, {
        doc: {
          [name]: {
            "target_hr":  { $set: e.target.value}
          }
        }
      });
    } else {
      const key = [e.target.id || e.target.name];
      if (["warm_up", "cool_down"].includes(name)) {
        const secs = Number(e.target.value);
        newState = update(this.state, {
          doc: {
            [name]: {
              duration: { $set: secs || 3 },
              durationv2: { $set: secs },
              title: {
                $set: title
              }
            }
          }
        });
      } else {
        newState = update(this.state, {
          doc: {
            [name]: {
              [key]: {
                $set: Number(e.target.value)
              },
              title: {
                $set: title
              }
            }
          }
        });
      }
    }
    const totalDuration = this.calculateDuration(newState.doc);
    newState = update(newState, {
      doc: {
        duration: {
          $set: totalDuration
        }
      }
    });

    this.setState({ ...newState, errors });
  };

  calculateDuration = doc => {
    const { cool_down, warm_up, steady_state, high_intensity, rounds } = doc;
    const cool_down_dur = isNaN(cool_down.durationv2) ? (isNaN(cool_down.duration) ? 0 : cool_down.duration) : cool_down.durationv2;
    const warm_up_dur = isNaN(warm_up.durationv2) ? (isNaN(warm_up.duration) ? 0 : warm_up.duration) : warm_up.durationv2;
    const steady_state_dur = isNaN(steady_state.duration)
      ? 0
      : steady_state.duration;
    const high_intensity_dur = isNaN(high_intensity.duration)
      ? 0
      : high_intensity.duration;
    const totalDuration =
      cool_down_dur +
      warm_up_dur +
      rounds * (steady_state_dur + high_intensity_dur);

    return totalDuration;
  };

  handleEditMode = () => {
    this.setState({editMode: true});
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  };

  handleCancel = () => {
    const newState = update(this.state, {
      doc: {
        $set: _.cloneDeep({ ...this.state.oldDoc, ...this.props.scheduleDoc })
      },
      editMode: {
        $set: false
      }
    });
    this.setState({ ...newState, errors: {} });
  };

  removeMedia = () => {
    const newState = update(this.state, {
      doc: {
        thumbnail: {
          $set: ""
        }
      }
    });

    this.setState({ ...newState, errors: {} });
  };

  valid = () => {
    let out = true;
    let errors = {};
    const { doc } = this.state;

    const {
      title="",
      ref_name="",
      sub_type,
      duration,
      rounds,
      cool_down,
      warm_up,
      distance,
    } = doc;
    if (!title.trim()) {
      errors.title = ERROR_TITLE("display name");
      out = false;
    }
    if (!ref_name.trim()) {
      errors.ref_name = ERROR_REF_NAME;
      out = false;
    }
    if (!duration && !distance) {
      errors.duration = true;
      errors.distance = true;
      out = false;
    }
    if (sub_type === "liss") {
      const [minHr, maxHr] = doc.target_hr;
      if((!!minHr && !maxHr)||(!!maxHr && !minHr)){
        errors.target_hr="Please enter valid Heart Rate";
        out = false;
      }
      if (!!minHr && !!maxHr && ((minHr < 60 || minHr > 200)||(maxHr < 60 || maxHr > 200))) {
        errors.target_hr= ERROR_CARIO_DUR;
        out = false;
      }
      if (!!minHr && !!maxHr && (minHr >= maxHr)) {
        errors.target_hr = "Min value should be less than max value";
        out = false;
      }
    } else {
      errors.steady_state = {};
      errors.steady_state.target_hr = [];
       if (
         !!doc.steady_state.target_hr[0] &&
         (doc.steady_state.target_hr[0] < 60 ||
           doc.steady_state.target_hr[0] > 200)
       ) {
         errors.steady_state.target_hr[0] = ERROR_CARIO_DUR;
         out = false;
       }
      if (
        !!doc.steady_state.target_hr[1] &&
        (doc.steady_state.target_hr[1] < 60 ||
          doc.steady_state.target_hr[1] > 200)
      ){
        errors.steady_state.target_hr[1] = ERROR_CARIO_DUR;
        out = false;
      }
      if (
        doc.steady_state.target_hr[0] &&
        doc.steady_state.target_hr[1] &&
        doc.steady_state.target_hr[0] >= doc.steady_state.target_hr[1]
      ) {
        errors.steady_state.target_hr[0] =
          "Min value should be less than max value.";
        out = false;
      }
      if (!doc.steady_state.duration) {
        errors.steady_state.duration = "Please provide valid value";
        out = false;
      }
      errors.high_intensity = {};
      errors.high_intensity.target_hr = [];
      if (
        !!doc.high_intensity.target_hr[0] && 
        (doc.high_intensity.target_hr[0] < 60 ||
          doc.high_intensity.target_hr[0] > 200)
      ) {
        errors.high_intensity.target_hr[0] = ERROR_CARIO_DUR;
        out = false;
      }
      if (
        doc.high_intensity.target_hr[1] &&
        (doc.high_intensity.target_hr[1] < 60 ||
        doc.high_intensity.target_hr[1] > 200)
      ) {
        errors.high_intensity.target_hr[1] = ERROR_CARIO_DUR;
        out = false;
      }
      if (
        doc.high_intensity.target_hr[0] &&
        doc.high_intensity.target_hr[1] &&
        doc.high_intensity.target_hr[0] >= doc.high_intensity.target_hr[1]
      ) {
        errors.high_intensity.target_hr[0] =
          "Min value should be less than max value.";
        out = false;
      }
      if (!doc.high_intensity.duration) {
        errors.high_intensity.duration = "Please provide valid value";
        out = false;
      }
      if (!warm_up.duration && warm_up.duration !== 0) {
        errors.warm_up = "Please provide valid value";
        out = false;
      }
      if (!cool_down.duration && cool_down.duration !== 0) {
        errors.cool_down = "Please provide valid value";
        out = false;
      }
      if (!rounds) {
        errors.rounds = "Please provide a valid number of rounds";
        out = false;
      }
    }
    this.setState({ errors });
    if((errors.distance || errors.duration) && (!errors.ref_name && !errors.title))
      this.props.enqueueSnackbar(durationErrorMsg, { variant: "error"});
    return out;
  };

  deleteItem = () => {
    const { onDelete, enqueueSnackbar, hideLoader, showLoader } = this.props;
    showLoader();
    woRepo(this.context.cid,this.draftFeature)
      .delete(this.docId)
      .then((doc)=>{
        hideLoader();
        if(doc){
          this.props.delete(doc.id);
          this.props.deleteWocardio(doc.id);
          if (!!onDelete) onDelete();
          enqueueSnackbar("Cardio deleted successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }

  copyItem = () =>{
    const {
      showLoader,
      hideLoader,
      insert,
      onSave,
      enqueueSnackbar
    } = this.props;
    const { doc } = this.state;
    showLoader();
    let copyDoc = update(doc,{
      ref_name:{
        $set:`Copy of ${doc.ref_name}`
      }
    })
    woRepo(this.context.cid,this.draftFeature)
          .create(copyDoc)
          .then(doc => {
            hideLoader();
            if (doc) {
              const _rdxState = {
                cid: this.context.cid,
                ref_name: doc.data().ref_name,
                type: doc.data().type,
                sub_type: doc.data().sub_type,
                duration: doc.data().duration,
                thumbnail : doc.data().thumbnail,
                publish_status:doc.data().publish_status
              }
              insert({
                _id: doc.id,
                data: _rdxState
              });
              this.props.insertWocardio({
                _id: doc.id,
                data: _rdxState
              });
              if (!!onSave) onSave(doc);
              hideLoader();
              enqueueSnackbar("Cardio copy successfully.", { variant: "success" });
            }
          })
          .catch(err => {
            hideLoader();
            enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
            Sentry.captureException(err);
          });

  }
  handleDraft=()=>{
    this.onSubmit(DRAFT);
  }
  handlePublish=async()=>{
    if(this.isDraftAvailable && this.state.showingDoc!==DRAFT){
      await this.toggleDoc(DRAFT);
    }
    this.onSubmit(PUBLISHED);
  }

  onSubmit = async( docType = PUBLISHED, addToLibrary = false ) => {
    const {
      showLoader,
      hideLoader,
      insert,
      onSave,
      enqueueSnackbar
    } = this.props;
    const { doc } = this.state;
    const { thumbnail } = doc;
    if (!this.valid()) return;
    showLoader();
    const calculatedDocType=calculatePublishStatus(this.isNew,this.draftFeature,this.state.oldDoc,docType);
    const isPublish=calculatedDocType===DRAFT_STATES['PUBLISHED'];

    let updatedDoc = { ...doc };
    updatedDoc['desc']=updatedDoc['desc'].trim();
    if (thumbnail.file) {
      const imageUrl = await uploadFile({
        file: thumbnail.file,
        filePath: WORKOUTS_STORAGE_FILE_PATH
      });
      updatedDoc = update(updatedDoc, {
        thumbnail: {
          $set: imageUrl.replace("original","240")
        }
      });
    }
    //---TODO ⬇︎ :replace docType with calculatedDocType ?? 
    const _rdxState = {
      cid: this.context.cid,
      ref_name: updatedDoc.ref_name,
      type: updatedDoc.type,
      sub_type: updatedDoc.sub_type,
      duration: updatedDoc.duration,
      thumbnail: (calculatedDocType===DRAFT_STATES['DRAFT_ONLY']||calculatedDocType===DRAFT_STATES["PUBLISHED"])?updatedDoc.thumbnail:_.get(this.state.publishedDoc,'thumbnail'),
      publish_status:calculatedDocType
    }
    //-------
    if (this.isNew) {
      woRepo(this.context.cid,this.draftFeature)
        .create(updatedDoc)
        .then(doc => {
          this.context.analytics.trackEvent(COMMON_ANALYTICS_PROPS.CARDIO_CREATED);
          hideLoader();
          if (doc) {
            insert({
              _id: doc.id,
              data: _rdxState
            });
            this.props.insertWocardio({
              _id: doc.id,
              data: _rdxState
            });
            if(this.props.scheduleSaveCardioEdit){
              this.props.scheduleSaveCardioEdit(updatedDoc);
            };
            if (!!onSave) onSave(doc);
            hideLoader();
          }
        })
        .catch(err => {
          hideLoader();
          console.log(err, this.state.doc, '>>');
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    } else {
      let request = () => woRepo(this.context.cid,this.draftFeature).update(this.docId, updatedDoc,null,docType);
      //---TODO:Test this with draft feature
      if (this.props.createNew) {
        if(!updatedDoc.srcId) updatedDoc.srcId = this.docId;
        updatedDoc.userProfileId = this.props.userProfileId;
        if(addToLibrary){
          updatedDoc.ref_name = `${DUPLICATE_REF_PREFIX} ${updatedDoc.ref_name}`;
          updatedDoc.archive = false;
        } else {
          updatedDoc.archive = true;
        }
        const docRef = await firebase.firestore().collection(`companies/${this.context.cid}/workouts`).doc(`modified_${uuidv4()}`);
        await docRef.set(updatedDoc)
        request = () =>  docRef.get();
      }
      //---
      request()
        .then(doc => {
          hideLoader();
          if (doc) {
            this.props.update({
              _id: doc.id,
              data: _rdxState
            });
            this.props.updateWocardio({
              _id: doc.id,
              data:_rdxState
            });
            if (!!onSave) onSave(doc);
            if(this.props.scheduleSaveCardioEdit){
              this.props.scheduleSaveCardioEdit(updatedDoc, doc.id,this.docId);
            };
            this.setState(o=>({
              editMode: false,
              showingDoc:docType,
              doc: {...updatedDoc,publish_status: doc.data().publish_status},
              publishedDoc:docType===PUBLISHED?doc.data():{...(o.publishedDoc||{}),publish_status:doc.data().publish_status},
              draftDoc:docType===DRAFT?doc.data():{...(o.draftDoc||{})},
              oldDoc: {...updatedDoc,publish_status: doc.data().publish_status},
              isLibraryConfirmationOpen: false
            }));
          }
        })
        .catch(err => {
          hideLoader();
          this.setState({ isLibraryConfirmationOpen: false})
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        })
    }
  };
  toggleDoc=(docType)=>{
    const { draftDoc, publishedDoc } = this.state;
    const doc = docType === DRAFT ? { ...draftDoc } : { ...publishedDoc };
    if (!Object.keys(doc).length) return;
    this.setState({ showingDoc: docType,doc,oldDoc:doc });
  }
  discardDraft=()=>{
    const { showLoader, hideLoader, enqueueSnackbar, onDelete } = this.props;
    showLoader();
    woRepo(this.context.cid,this.draftFeature)
    .deleteDraft(this.docId,null,this.state.doc.publish_status)
    .then((doc)=>{
      hideLoader();
      if (!doc) return;
      const updatedDoc = doc.data();
      if (_.get(updatedDoc, "publish_status", "") === DRAFT_STATES["DRAFT_ONLY"]) {
        this.props.delete(doc.id);
        this.props.deleteWocardio(doc.id);
        if (!!onDelete) onDelete();
      } else if (_.get(updatedDoc, "publish_status", "") === DRAFT_STATES["PUBLISHED"]) {
        this.setState(o=>({
          oldDoc: { ...updatedDoc },
          doc: { ...updatedDoc },
          publishedDoc:{...o.publishedDoc,publish_status:PUBLISHED},
          showingDoc:PUBLISHED
        }));
        this.props.update({
          _id:doc.id,
          data:{
            cid: this.context.cid,
            ref_name: updatedDoc.ref_name,
            type: updatedDoc.type,
            sub_type: updatedDoc.sub_type,
            duration: updatedDoc.duration,
            thumbnail : updatedDoc.thumbnail,
            publish_status:updatedDoc.publish_status,
          }
        })
      }
      enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
    })
    .catch((err) => {
      hideLoader();
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    });
  }

  handleSubmit = () => {
    if(this.props.createNew) {
      this.setState({isLibraryConfirmationOpen: true});
    } else {
      this.onSubmit();
    }
  }

  closeLibraryConfirmation = () => {
    this.setState({isLibraryConfirmationOpen: false})
  }

  render() {
    const { doc,oldDoc, errors, editMode, loader, isValidId,showingDoc, isLibraryConfirmationOpen } = this.state;
    const { scheduleDoc = {}, loading, isPreview = false,showOnlyEdit=false } = this.props;
    if (!isValidId) return <PageNotFound keyName="cardio" />;
    if (this.isNew)
      return (
        <CardioForm
          loading={loading}
          isNew
          doc={doc}
          errors={errors}
          handleChange={this.handleChange}
          onCancel={this.props.onCancel}
          onSubmit={this.onSubmit}
          handleRanges={this.handleRanges}
        />
      );
    return (
      <div className={`overflow-x-hidden ${doc ? "" : "h-100"} position-relative`}>
        {loader && <CircularLoader className="position-absolute" />}
        {doc && !loader && (
          <Details
            doc={{ ...oldDoc, ...scheduleDoc }}
            handleEditMode={this.handleEditMode}
            isPreview={isPreview}
            showOnlyEdit={showOnlyEdit}
            copyItem={this.copyItem}
            deleteItem={this.deleteItem}
            disableActions={this.props.disableActions}
            createNew={this.props.createNew}
            draftBannerProps={{
              toggleDoc:this.toggleDoc,
              isDraftAvailable:this.isDraftAvailable,
              handlePublish:this.handlePublish,
              discardDraft:this.discardDraft,
              showingDoc:showingDoc,
            }}
          />
        )}
        {editMode && (
          <CardioForm
            loading={loading}
            doc={doc}
            errors={errors}
            handleChange={this.handleChange}
            handleChips={this.handleChips}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            handleRanges={this.handleRanges}
            handleDraft={this.handleDraft}
            handlePublish={this.handlePublish}
            draftFeature={this.draftFeature}
            isDraftAvailable={this.isDraftAvailable}
          />
        )}
        <LibraryConfirmation 
          open={isLibraryConfirmationOpen}
          onClose={this.closeLibraryConfirmation}
          action1={this.onSubmit}
          action2={() => this.onSubmit(PUBLISHED, true)}
        />
      </div>
    );
  }

  componentDidMount() {
    if (!this.isNew) this.getCardioDoc();
  }
  componentDidUpdate(prevProps){
    if(prevProps.showOnlyEdit!=="copy" && this.props.showOnlyEdit==="copy" && this.props.scheduleSaveCardioEdit){
      //send back the doc;
      this.props.scheduleSaveCardioEdit(this.state.doc);
    }
  }

  getCardioDoc =async () => {
    try{
    this.setState({ loader: true });
    let doc,
    oldDoc=_.cloneDeep({ ...this.state.oldDoc }),
    publishedDoc=_.cloneDeep({ ...this.state.publishedDoc }),
    draftDoc=_.cloneDeep({ ...this.state.draftDoc }),
    showingDoc;
    //get published doc
    const cardioDoc = !this.props.scheduleDoc ? await this.fetchCardio():this.props.scheduleDoc;
    //TODO: Test with scheduleDoc
    if(!(cardioDoc.exists||this.props.scheduleDoc)){
      this.setState({
        isValidId: false,
        loader: false,
      });
      return;
    }
    publishedDoc=cardioDoc.data?cardioDoc.data() : cardioDoc;
    //if draft feature is off, treat main collection doc as published
    if(!this.draftFeature){
      publishedDoc['publish_status']=PUBLISHED;
    }
    const publish_status = _.get(publishedDoc, "publish_status", PUBLISHED);
    if (publish_status.includes("draft")) {
      //get draft doc
      const draftCardio = await this.fetchCardio(true);
      draftDoc = !!draftCardio.data ? draftCardio.data() : draftCardio;
    }
    if (publish_status === DRAFT_STATES["DRAFT_ONLY"]) {
      doc = { ...draftDoc };
      oldDoc = { ...draftDoc };
      showingDoc = DRAFT;
    }
    else{
      doc = { ...publishedDoc };
      oldDoc = { ...publishedDoc };
      showingDoc = PUBLISHED;
    }
    this.setState({
      doc,
      oldDoc,
      publishedDoc,
      draftDoc,
      loader:false,
      showingDoc
    })
    }
    catch(err){
      const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
      this.props.enqueueSnackbar(msg, {
        variant: "error",
      });
      this.setState({loader:false})
    }
  };
  fetchCardio=async(isDraft = false)=>{
     if (this.props.loading) return;
    return woRepo(this.context.cid,this.draftFeature).doc(this.docId,null,isDraft);
  }
}

const mapStateToProps = (s, op) => {
  const id = op.id;
  if (id === "new")
    return {
      doc: { ..._default },
      loading: s.cardio.loading
    };
  else return { doc: undefined, loading: s.cardio.loading
   };
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const cardioFns = cardioRdxFns(d);
  const { insert: insertWocardio, update: updateWocardio, delete: deleteWocardio } = woCardioRdxFns(d);
  return {
    ...cardioFns,
    insertWocardio,
    updateWocardio,
    deleteWocardio,
    showLoader,
    hideLoader
  };
};

const CardioInfoWrapper=(props)=>{
  const {convertor} = useContext(Conversion);
  return <CardioInfo {...props} convertor={convertor}/>
}
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(CardioInfoWrapper)
);
