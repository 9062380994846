import React, { useEffect, useContext, useState } from "react";
import firebase from "fitbud/firebase";
import { BrowserRouter, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Sentry from '@sentry/browser';
import { CircularProgress } from "@material-ui/core";
import NavContextProvider from "fitbud/providers/appNav";
import WContextProvider from "fitbud/providers/workerProvider";
import ConversionProvider from "fitbud/providers/conversion";
import WebsiteProvider from "fitbud/providers/websiteProvider";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { AnalyticsProvider } from "fitbud/providers/analytics";
import Appcues from "fitbud/utils/appcues";
import AppDrawer from "fitbud/partials/appDrawer";
import Routes from "./routes";
import appRdxFns from "fitbud/redux/app";
import homeRdxFns from "fitbud/redux/home";
import { fetchCIDStats } from "fitbud/api";
import moment from "moment";
import { messaging } from "fitbud/firebase";
import LiveSessionsProvider from "fitbud/providers/liveSessionsProvider";
import LiveSession from "fitbud/views/liveSessions/videoScreen";
import _ from "lodash";
import AppAlert from "fitbud/components/appAlert";
import { RoleContext } from "fitbud/providers/roleProvider";
import { bffFCMSubscribe } from "fitbud/api";
import AclStatusBar from "fitbud/components/acl/acl-satus-bar";
import BillingProvider from "fitbud/providers/billing-provider";
import { LocationProvider } from 'fitbud/providers/gcLocationsProvider';
import {ServiceProvider} from "fitbud/providers/services-provider";
import ExploreConfigProvider from "fitbud/views/exploreV2/config/exploreConfigProvider";

const daysBefore = 7;
const expiryGracePeriod= 7;
const TrackedRoutes = withRouter(Routes);

function doSubscribe(cid, permission) {
  if (permission !== "granted") return console.error('PUSH permission not granted');
  return messaging.getToken()
    .then(token => bffFCMSubscribe(cid, token))
    .catch(console.error);
}

function fcmSubscribe(cid) {
  if (!messaging) return null;
  if (!('Notification' in window))
    return console.error('PUSH permission not granted');
  messaging.onMessage(payload => {
    const data = payload.notification
    const { title, body, icon, click_action} = data || {};
    if (!title) return;
    new Notification(title, { body, icon, click_action });
  });
  if (Notification.permission === 'granted')
    doSubscribe(cid, 'granted');
  else if (Notification.permission !== "denied")
    Notification.requestPermission(permission => doSubscribe(cid, permission))
      .then(permission => doSubscribe(cid, permission)).catch(console.error);
};

const Loader = props => {
  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
      <CircularProgress />
    </div>
  );
};

const Router = props => {
  const { setCID, setStats } = props;
  const { cid, comp } = useContext(FirebaseAuthContext);
  const [reload, setReload] = useState(false);
  const isVideoCalling =  Boolean(comp && (_.get(comp.data(), "features.video_calling")));
  const { loading, allClientAccess } = useContext(RoleContext);
  useEffect(() => {
    if (allClientAccess && reload) return; // owner doesn't need to reload. It only needs to load once
    if (!allClientAccess && !reload) return; // non-owner needs only reload. They don't need initial load at all
    const local=moment().local();
    const today = local.format("YYYYMMDD");
    const maxDate = moment(local).add(daysBefore, 'days').format("YYYYMMDD");
    const minDate = moment(local).subtract(expiryGracePeriod, 'days').format("YYYYMMDD");
    fetchCIDStats(cid, today , maxDate, minDate).then(({ data }) => {
        setStats(data);
      }).catch(Sentry.captureException);
  }, [cid, allClientAccess, reload]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(()=> {
    if(!!setCID && !!cid)
      setCID(cid);

    Promise.all([fcmSubscribe(cid)]).catch(console.error);

    const unsubscribe = firebase
      .firestore()
      .doc(`companies/${cid}/misc/counters`)
      .onSnapshot(function(doc) {
        const data = doc.data();
        const { groupChats, _muat, _cuat } = data;
        const userStats = allClientAccess ? { ...data } : { groupChats };
        setStats(userStats);
        setTimeout(() => setReload(current => {
          if (_muat && _muat.seconds && (!current || current < _muat.seconds)) return _muat.seconds;
          if (_cuat && _cuat.seconds && (!current || current < _cuat.seconds)) return _cuat.seconds;
          return current || true;
        }), 1000);
      });
    return () => unsubscribe && unsubscribe();
  },[cid]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <BrowserRouter>
      <WContextProvider>
        <AnalyticsProvider>
          <BillingProvider>
          <NavContextProvider>
            {loading && <Loader />}
            {!loading && (
              <div className="d-flex">
                <Appcues />
                <AppDrawer />
                <div className="d-flex flex-column flex-grow-1 align-items-stretch vh-100 min-vh-100 w-mn0 p-height-unset">
                  <AclStatusBar />
                  <div className="d-flex flex-column flex-grow-1 " style={{minHeight:0}}>
                    <WebsiteProvider>
                      <ConversionProvider>
                        <LocationProvider>
                          <ServiceProvider>
                          <ExploreConfigProvider>
                            {/* Wrap inside scheduler context if cide- calling feature is enabled */}
                            {isVideoCalling ? (
                              <LiveSessionsProvider>
                                <TrackedRoutes />
                                <AppAlert isVideoCalling={isVideoCalling} />
                                <LiveSession />
                              </LiveSessionsProvider>
                            ) : (
                              <>
                                <TrackedRoutes />
                                <AppAlert />
                              </>
                            )}
                          </ExploreConfigProvider>
                          </ServiceProvider>
                        </LocationProvider>
                      </ConversionProvider>
                    </WebsiteProvider>
                  </div>
                </div>
              </div>
            )}
          </NavContextProvider>
          </BillingProvider>
        </AnalyticsProvider>
      </WContextProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  files: state.fileUpload.files,
});

const mapDispatchToProps = dispatch => {
  const { setCID }  = appRdxFns(dispatch);
  const { set: setStats }  = homeRdxFns(dispatch);
  return {
    setCID,
    setStats
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Router);
