import React, { useMemo, useState, useContext } from "react";
import { Typography, Divider, Chip } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import _ from "lodash";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { useDispatch } from "react-redux";
import appRdxFns from "fitbud/redux/app";
import { useSnackbar } from "notistack";
import { DEFAULT_ERROR } from "fitbud/utils/constants";
import Confirmation from "fitbud/components/confirmationDialog";
import {
  CLASS_PASSED_TEXT,
  DEFAULT_SCHEDULE_CONFLICT_MSG,
  getClassBookingStatus,
  getMode,
  isPastClass,
} from "fitbud/views/groupClasses/helper";
import { groupClassRegisterUser, groupClassRemoveUser } from "fitbud/api/gcFunctions";
import ClientHeader from "./header";
import ClientSearch from "./search";
import ClassUserList from "./classUserList";
import useLiveTime, { CLASS_RUNNING_STATUS } from "fitbud/hooks/useLiveTime";
import { JoinLiveGroupClass } from "../components/countDown";
const useClasses = makeStyles(() => ({
  chips: {
    backgroundColor: "#FFF",
    borderRadius: "40px",
    border: "1px solid #D3D9DB",
    color: "#0d0d0d",
    "& .MuiChip-label": {
      fontSize: "13px",
      fontWeight: "500",
    },
  },
  chipClickable: {
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    "&:focus": {
      backgroundColor: "#FFF",
    },
  },
  selected_chip: {
    color: "#317FF5",
    border: "1px solid #317FF5 !important",
  },
  waiting: {
    backgroundColor: "rgba(215, 55, 23, 0.10)",
    color: "#D73717",
    borderRadius: 3,
    padding: "3px 8px",
    marginLeft: 10,
  },
}));

const ClientContent = (props) => {
  const {
    data,
    instanceId,
    users,
    usersMemo,
    staffId,
    isFilter,
    setFilter,
    filter,
    onClose,
    userFetching,
    refreshClass,
    forceDisabled = false,
    view_mode,
  } = props;
  const classes = useClasses();
  const dispatch = useDispatch();
  const { cid } = useContext(FirebaseAuthContext);
  const [selectedForAllowed, setSelectedForAllowed] = useState(""); //use for confirmation adn stored id:
  const [selectedForCancel, setSelectedForCancel] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const closeSelectedAllowed = () => setSelectedForAllowed(null);
  const closeSelectedRemove = () => setSelectedForCancel(null);
  const { isOnLine, isHybrid, isOffline } = getMode(data.mode);
  const { isPast, active } = data || {};
  const [status_offline, status_online] = useMemo(() => {
    const { online, offline } = getClassBookingStatus(data, false);
    return [offline, online ];
  }, [data]);
  const [applied_mode, applied_status] = filter || [];
  const { duration, time } = data;
  const [liveStatus] = useLiveTime(time, duration);
  const handleSearchOpen = (value) => setSearchOpen(value);
  const isDisabled = useMemo(() => {
    return isPast || !active || forceDisabled; //either class
  }, [active, isPast, forceDisabled]);
  const isValidAction = () => {
    const isPast = isPastClass(data.local_time, data.duration);
    if (isPast) {
      enqueueSnackbar(CLASS_PASSED_TEXT, { variant: "error" });
    }
    return !isPast;
  };
  const onSelectAllowed = (id) => {
    if (!isValidAction()) return;
    setSelectedForAllowed(id);
  };
  const onSelectCanceled = (id) => {
    if (!isValidAction()) return;
    setSelectedForCancel(id);
  };

  const handleAllowed = async () => {
    const _uid = (selectedForAllowed || "").split(":").pop();
    try {
      const params = {
        cid,
        instanceId: instanceId,
        uid: _uid,
        mode: _.get(data, `users.${_uid}.mode`),
        checkPurchases: true,
        staffId,
      };
      showLoader();
      const res = await groupClassRegisterUser(params);
      const { success, message } = res.data;
      if (!success) {
        enqueueSnackbar(message || DEFAULT_SCHEDULE_CONFLICT_MSG, { variant: "error" });
        hideLoader();
        return;
      }
      _.delay(() => {
        refreshClass(instanceId, closeSelectedAllowed);
      }, 500);
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      throw new Error(err);
    } finally {
      hideLoader();
    }
  };

  const handleRemove = async () => {
    const _uid = (selectedForCancel || "").split(":").pop();
    try {
      const params = {
        cid,
        instanceId: instanceId,
        uid: _uid,
        staffId,
      };
      showLoader();
      const res = await groupClassRemoveUser(params);
      const { success, message } = res.data;
      if (!success) {
        enqueueSnackbar(message, { variant: "error" });
        hideLoader();
        return;
      }
      _.delay(() => {
        refreshClass(instanceId, closeSelectedRemove);
      }, 500);
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      throw new Error(err);
    } finally {
      hideLoader();
    }
  };

  const applyFilter = (mode, status) => {
    if (applied_mode === mode && applied_status === status) setFilter(undefined);
    else setFilter([mode, status]);
  };

  const getSelectedClass = (mode, status) => {
    if (applied_mode === mode && applied_status === status) return classes.selected_chip;
    return "";
  };

  const getUserInfo = (userId) => {
    const _uid = (selectedForAllowed || "").split(":").pop();
    return _.get(usersMemo, `current.${_uid}.email`);
  };

  return (
    <React.Fragment>
      {!!searchOpen && (
        <ClientSearch
          {...props}
          onClose={() => handleSearchOpen(false)}
          onSelectAllowed={onSelectAllowed}
          onSelectCanceled={onSelectCanceled}
          mode={data.mode}
          isPast={isPast}
        />
      )}
      {!searchOpen && (
        <React.Fragment>
          <ClientHeader
            filter={filter}
            staffId={staffId}
            onClose={onClose}
            data={data}
            usersMemo={usersMemo}
            openSearch={() => handleSearchOpen(true)}
            userFetching={userFetching}
            instanceId={instanceId}
            refreshClass={refreshClass}
            isValidAction={isValidAction}
            isDisabled={isDisabled}
          />
          <div className="d-flex flex-column overflow-hidden flex-grow-1">
            <div className="px-25 py-20">
              {isHybrid && (
                <Typography className="text-0d0d0d font_15_500 mb-15">
                  <span className="font_15_700">{status_offline.booked + status_online.booked}</span>/
                  <span>{status_offline.capacity + status_online.capacity}</span> <span>Booked</span>
                </Typography>
              )}
              {isOffline && (
                <Typography className="text-0d0d0d font_15_500 mb-15">
                  <span className="font_15_700">{status_offline.booked}</span>/<span>{status_offline.capacity}</span>{" "}
                  <span>Booked</span>
                </Typography>
              )}
              {isOnLine && (
                <Typography className="text-0d0d0d font_15_500 mb-15">
                  <span className="font_15_700">{status_online.booked}</span>/<span>{status_online.capacity}</span>{" "}
                  <span>Booked</span>
                </Typography>
              )}
              {/* ----- Quick Filters ------ */}
              <div className="d-flex">
                {/* If class passed after rendering then add filters for joined users */}
                {isPast ? (
                  <>
                    <Chip
                      className={clsx(classes.chips, "mr-12 cursor-pointer", getSelectedClass("all", "joined"))}
                      label={
                        <span className="font_13_500">
                          <span className="font_13_700">{(status_online?.joined + status_offline?.joined)} &nbsp;</span>
                          Joined
                        </span>
                      }
                      classes={{ clickable: classes.chipClickable }}
                      onClick={() => applyFilter("all", "joined")}
                    />
                    <Chip
                      className={clsx(classes.chips, "mr-12 cursor-pointer", getSelectedClass("all", "missed"))}
                      label={
                        <span className="font_13_500">
                          <span className="font_13_700">{status_online?.missed + status_offline?.missed } &nbsp;</span>
                          Missed
                        </span>
                      }
                      classes={{ clickable: classes.chipClickable }}
                      onClick={() => applyFilter("all", "missed")}
                    />
                    <Chip
                      className={clsx(classes.chips, "mr-12 cursor-pointer", getSelectedClass("all", "waiting"))}
                      label={
                        <span className="font_13_500">
                          <span className="font_13_700">{status_offline?.exact_waiting + status_online?.exact_waiting} &nbsp;</span>
                          Waiting
                        </span>
                      }
                      classes={{ clickable: classes.chipClickable }}
                      onClick={() => applyFilter("all", "waiting")}
                    />
                  </>
                ) : (
                  <>
                    {/* Quick Filters for live or upcoming classes */}
                    {isHybrid ? (
                      <>
                        <Chip
                          className={clsx(classes.chips, "mr-12 cursor-pointer", getSelectedClass("offline", "booked"))}
                          label={
                            <span className="font_13_500">
                              {" "}
                              <span className="font_13_700">{status_offline.booked} &nbsp;</span>
                              In Person
                            </span>
                          }
                          classes={{ clickable: classes.chipClickable }}
                          onClick={() => applyFilter("offline", "booked")}
                        />
                        <Chip
                          className={clsx(classes.chips, "mr-12 cursor-pointer", getSelectedClass("online", "booked"))}
                          onClick={() => applyFilter("online", "booked")}
                          label={
                            <span className="font_13_500  ">
                              <span className="font_13_700">{status_online.booked} &nbsp;</span>
                              Online
                            </span>
                          }
                          classes={{ clickable: classes.chipClickable }}
                        />
                        <Chip
                          className={clsx(classes.chips, "mr-12 cursor-pointer", getSelectedClass("hybrid", "waiting"))}
                          onClick={() => applyFilter("hybrid", "waiting")}
                          label={
                            <span className="font_13_500 ">
                              <span className="font_13_700">
                                {status_online.waiting + status_offline.waiting} &nbsp;
                              </span>
                              Waiting
                            </span>
                          }
                          classes={{ clickable: classes.chipClickable }}
                        />
                      </>
                    ) : (
                      <>
                        <Chip
                          className={clsx(
                            classes.chips,
                            "mr-12 cursor-pointer",
                            getSelectedClass(isOffline ? "offline" : "online", "booked")
                          )}
                          onClick={() => applyFilter(isOffline ? "offline" : "online", "booked")}
                          label={
                            <span className="font_13_500 ">
                              {" "}
                              <span className="font_13_700">{status_offline.booked} &nbsp;</span>
                              Confirmed
                            </span>
                          }
                          classes={{ clickable: classes.chipClickable }}
                        />
                        <Chip
                          className={clsx(
                            classes.chips,
                            "mr-12 cursor-pointer",
                            getSelectedClass(isOffline ? "offline" : "online", "waiting")
                          )}
                          onClick={() => applyFilter(isOffline ? "offline" : "online", "waiting")}
                          label={
                            <span className="font_13_500">
                              <span className="font_13_700">{status_offline.waiting} &nbsp;</span>
                              Waiting
                            </span>
                          }
                          classes={{ clickable: classes.chipClickable }}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
              {liveStatus === CLASS_RUNNING_STATUS.LIVE && active && (
                <div className="mt-15 d-flex">
                  <JoinLiveGroupClass data={data} style={{ borderRadius: 4 }} />
                </div>
              )}
            </div>
            <Divider className="mb-12" />
            <ClassUserList
              users={users}
              isFilter={isFilter}
              onSelectAllowed={onSelectAllowed}
              onSelectCanceled={onSelectCanceled}
              mode={data.mode}
              isDisabled={isDisabled}
              isPast={isPast}
              view_mode={view_mode}
            />
          </div>
        </React.Fragment>
      )}
      {!!selectedForAllowed && (
        <Confirmation
          open
          handleChange={handleAllowed}
          handleCancel={closeSelectedAllowed}
          title="Are you sure"
          confirmOption="Yes, Allow"
          msg={`Are you sure you wanted to allow this user : ${getUserInfo(selectedForAllowed)} to join the class `}
        />
      )}
      {!!selectedForCancel && (
        <Confirmation
          open
          handleChange={handleRemove}
          handleCancel={closeSelectedRemove}
          title="Are you sure"
          confirmOption="Yes, Remove"
          msg={`Are you sure you wanted to remove this user : ${getUserInfo(
            selectedForCancel
          )} from the class, after that he will not able to join this class by own. `}
        />
      )}
    </React.Fragment>
  );
};

export default ClientContent;
